export const audioType = ['mp3', 'wav', 'ogg', 'aac', 'm4a']

export const videoType = ['mp4', 'mov']

export const imageType = ['jpg', 'jpeg', 'png']

export const flashType = ['swf']

export const supportPreviewFileType = ['mp3', 'wav', 'ogg', 'aac', 'm4a', 'mp4', 'mov', 'swf']

export const speakType = ['mp4']
