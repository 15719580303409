<template>
  <courseware-form
    :is-version-detail="pageState.action === 'version-detail'"
    :is-edit="pageState.isEdit"
    :title="pageState.title"
    v-model:current="pageState.current"
    :readOnly="pageState.readOnly"
    @onSave="handleSave"
  />
  <div class="content">
    <a-row>
      <a-col :span="pageState.current.type !== 'plan' ? 10 : 20">
        <a-descriptions
          :bordered="false"
          :column="{ sm: 1 }"
          v-if="!pageState.readOnly"
        >
          <template #title>
            <span>课件上传</span>
            <a-tooltip placement="top">
              <template #title>
                <p>支持上传的文件格式如下</p>
                <p>
                  <span>图片</span
                  ><span v-for="v in imageType" :key="v">&nbsp;{{ v }}</span>
                </p>
                <p>
                  <span>视频</span
                  ><span v-for="v in videoType" :key="v">&nbsp;{{ v }}</span>
                </p>
                <p>
                  <span>音频</span
                  ><span v-for="v in audioType" :key="v">&nbsp;{{ v }}</span>
                </p>
                <p>
                  <span>Flash</span
                  ><span v-for="v in flashType" :key="v">&nbsp;{{ v }}</span>
                </p>
              </template>
              <QuestionCircleOutlined style="opacity: 0.5; margin-left: 12px" />
            </a-tooltip>
          </template>

          <a-descriptions-item>
            <a-upload
              :customRequest="handleUpload"
              v-model:file-list="fileList"
              :show-upload-list="false"
              name="file"
              :before-upload="beforeUpload"
            >
              <a-button>
                <upload-outlined />
                选择文件
              </a-button>
            </a-upload>
            <a-button
              style="margin-left: 12px"
              type="primary"
              danger
              @click="handleRemove"
              :disabled="!pageState.current.url"
            >
              清空
            </a-button>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-progress
              v-if="pageState.uploadPercent >= 0"
              :percent="pageState.uploadPercent"
            />
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="课件预览" :bordered="false" :column="{ sm: 1 }">
          <a-descriptions-item>
            <courseware-preview :current="pageState.current" />
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col span="14" v-if="pageState.current.type !== 'plan'">
        <a-descriptions title="文件信息" bordered :column="{ sm: 1, xs: 1 }">
          <a-descriptions-item label="地址">{{
            pageState.current.url || "--"
          }}</a-descriptions-item>
          <a-descriptions-item label="大小">{{
            pageState.current.size || "--"
          }}</a-descriptions-item>
          <a-descriptions-item label="md5">{{
            pageState.current.md5 || "--"
          }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { reactive, onBeforeMount, ref, computed } from 'vue'
import CoursewareForm from '@/views/courseware/components/CoursewareForm'
import {
  getCoursewareInfo,
  getCoursewareVersionInfo,
  createCourseware,
  editCourseware, setCoursewareTags
} from '@/services/courseware'
import {
  QuestionCircleOutlined,
  UploadOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { imageType, audioType, videoType, flashType } from '@/utils/fileUpload'
import { uploadFileToOss } from '@/utils/oss'
import CoursewarePreview from '@/views/courseware/components/CoursewarePreview'

export default {
  name: 'Edit',
  components: {
    CoursewareForm,
    UploadOutlined,
    CoursewarePreview,
    QuestionCircleOutlined
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const fileList = ref([])
    const loading = ref(false)

    const pageState = reactive({
      title: '新建课件',
      action: route.params.action,
      readOnly: route.params.action !== 'edit', // 是为预览， 否为编辑
      id: parseInt(route.params.id),
      current: {},
      uploadLoading: false,
      uploadPercent: -1,
      isEdit: false
    })

    const queryParams = computed(() => {
      const params = {
        author_type: 'mohuan',
        name: pageState.current.name,
        type: pageState.current.type,
        size: pageState.current.size,
        url: pageState.current.url,
        md5: pageState.current.md5,
        share_mode: pageState.current.share_mode
      }
      if (!pageState.current.book_section_id || pageState.current.book_section_id.length === 0) {
        params.book_section_id = 0
        params.book_id = 0
      } else if (pageState.current.book_section_id.length === 1) {
        params.book_id = pageState.current.book_section_id[0]
        params.book_section_id = 0
      } else {
        params.book_id = pageState.current.book_section_id[0]
        params.book_section_id = pageState.current.book_section_id[pageState.current.book_section_id.length - 1]
      }
      return params
    })

    // 初始化
    if (pageState.action === 'edit') {
      if (pageState.id) {
        pageState.title = '编辑课件'
      } else {
        pageState.title = '新建课件'
      }
    } else {
      pageState.title = '查看课件'
    }

    const handleSave = async () => {
      const notEmptyCheckArr = {
        url: '文件不能为空',
        book_id: '教材不能为空',
        name: '名称不能为空'
      }
      let finish = false
      for (const k in notEmptyCheckArr) {
        if (!queryParams.value[k]) {
          message.error(notEmptyCheckArr[k])
          finish = true
          break
        }
      }
      if (finish) return

      try {
        let coursewareId
        if (pageState.id) {
          await editCourseware(pageState.id, queryParams.value)
          coursewareId = pageState.id
        } else {
          const { id } = await createCourseware(queryParams.value)
          coursewareId = id
        }
        await setCoursewareTags(coursewareId, { tags: pageState.current.tags })
        message.success('保存成功')
        history.go(-1)
      } catch (e) {
        console.error(e)
        message.error('保存错误')
      }
    }

    const getInfo = async () => {
      if (!pageState.id) {
        return
      }
      const getInfoService = pageState.action === 'version-detail' ? getCoursewareVersionInfo : getCoursewareInfo
      pageState.current = await getInfoService(pageState.id)
      const sectionsId = pageState.current.book_section_id ?? 0
      const bookId = pageState.current.book_id ?? 0
      pageState.current.tags = pageState.current.tags.map(i => i.tag)
      pageState.current.bookShowName = pageState.current.book?.name ?? ''
      pageState.current.book_section_id = [bookId]
      if (pageState.current.book?.name && pageState.current.section?.name) {
        pageState.current.bookShowName += '-' + pageState.current.section.name
        pageState.current.book_section_id = [bookId, sectionsId]
      }
      console.log(44444, pageState.current)
    }

    onBeforeMount(async () => {
      await getInfo()
    })

    const checkFileType = (file) => {
      // file.name type
      const fileSuffixMap = {
        image: imageType,
        audio: audioType,
        video: videoType,
        swf: flashType
      }
      const index = file.name.lastIndexOf('.')
      const fileSuffix = file.name.substr(index + 1)
      for (const key in fileSuffixMap) {
        if (fileSuffixMap[key].includes(fileSuffix)) {
          return { errorCode: 0, type: key }
        }
      }
      return { errorCode: 1 }
    }

    const beforeUpload = file => {
      const { errorCode, type } = checkFileType(file)
      if (errorCode) {
        message.error('文件格式错误')
        return false
      }

      pageState.current.type = type
    }
    const showUploadPercent = (percent) => {
      pageState.uploadPercent = percent
    }
    const handleUpload = ({ file }) => {
      pageState.isEdit = true
      try {
        pageState.uploadLoading = true
        pageState.uploadPercent = 0
        uploadFileToOss(file, true, showUploadPercent).then(data => {
          const { url, md5, size } = data
          if (url.length > 256) {
            pageState.uploadPercent = -1
            message.error('上传失败，文件名称过长')
            return
          }
          pageState.current.url = url
          pageState.current.md5 = md5
          pageState.current.size = size
          fileList.value.forEach(item => {
            if (item.name === file.name) {
              item.url = url
              item.status = 'done'
            }
          })
          message.success('上传成功')
        }).finally(() => {
          pageState.uploadLoading = false
        })
      } catch (error) {
        console.log(error)
        message.error('上传失败，请重试')
      }
    }

    const handleRemove = () => {
      pageState.uploadPercent = -1
      pageState.current.type = ''
      pageState.current.url = ''
      pageState.current.md5 = ''
      pageState.current.size = ''
      pageState.current.updated_at = ''
    }

    return {
      imageType,
      audioType,
      videoType,
      flashType,
      fileList,
      loading,
      pageState,
      history,
      route,
      handleSave,
      beforeUpload,
      handleUpload,
      handleRemove
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  color: rgba(0, 0, 0, 0.85);
  background-color: white;
  width: 100%;
  padding: 24px;
  margin-top: 24px;
  .ant-row,
  .ant-form-item {
    margin-bottom: 5px;
  }
  .previewDiv {
    height: 100px;
    width: 100px;
    padding: 30px 0;
  }
}
</style>
