<template>
  <div class="content" style="margin-top: 0;padding-top: 0">
    <img style="max-width: 100%" v-if="pageState.type === 'image' && canPreview" :src="pageState.url" alt="文件" />
    <video style="max-width: 100%" v-else-if="pageState.type === 'video' && canPreview" controls="controls" :src="pageState.url" />
    <video style="max-width: 100%" v-else-if="pageState.type === 'speak' && canPreview" controls="controls" :src="pageState.url" />
    <audio style="max-width: 100%" v-else-if="pageState.type === 'audio' && canPreview" controls :src="pageState.url" />
    <div style="max-width: 100%" v-else-if="pageState.type === 'swf' && canPreview">
      该格式不支持在线预览，若需查看请<a :href="pageState.url">下载</a>后在电脑中查看
    </div>
    <div v-else-if="pageState.type === 'plan'" v-html="pageState.plan_content" style="text-align: left"></div>
    <a-empty v-else >
      <template #description>
        {{ description }}
      </template>
    </a-empty>
  </div>
</template>

<script>
import { computed } from 'vue'
import { supportPreviewFileType } from '@/utils/fileUpload'

export default {
  name: 'CoursewarePreview',
  props: {
    current: {
      type: Object
    }
  },
  setup (props) {
    const pageState = computed({
      get: () => props.current
    })
    const canPreview = computed(() => {
      if (!props?.current?.url) {
        return false
      }
      if (pageState.value.type === 'image') {
        return true
      }
      const index = props.current.url.lastIndexOf('.')
      const fileSuffix = props.current.url.substr(index + 1)
      return supportPreviewFileType.includes(fileSuffix)
    })
    const description = computed(() => {
      if (['audio', 'video'].includes(props.current.type) && props.current.url) {
        const index = props.current.url.lastIndexOf('.')
        const fileSuffix = props.current.url.substr(index + 1)
        if (!supportPreviewFileType.includes(fileSuffix)) {
          return '该格式暂不支持预览'
        }
      }
      return '暂无数据'
    })

    return {
      canPreview,
      description,
      pageState
    }
  }
}
</script>

<style scoped>
.content{
  text-align: center;
}
</style>
